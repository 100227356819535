<template>
  <div class="continer">
    <!-- card面板 -->
    <el-card class="box-card">
      <!-- 头部按钮 -->
      <div slot="header" class="clearfix">
        <el-row :gutter="20">
          <!-- span属性指的是占据多少列 -->
          <el-col :span="6">
            <el-button
              id="create"
              type="text"
              icon="el-icon-arrow-left"
              @click="backPage()"
              >返回</el-button
            >
          </el-col>
          <el-col :span="12">
            <!-- 考试名称-考试科目-监控组号 -->
            {{ this.$route.query.examName }} -
            {{ this.$route.query.subjectName }} -
            {{ this.$route.query.id }}
          </el-col>
        </el-row>
      </div>

      <div class="text item">
        <!-- 头部标题与form表单 -->
        <div class="body-header">
          <el-row :gutter="20">
            <!-- span属性指的是占据多少列 -->
            <el-col>
              <el-button
                icon="el-icon-plus"
                type="primary"
                class="hbtn"
                @click="openAddExaminee()"
              >
                添加指定考生
              </el-button>
              <el-button
                icon="el-icon-close"
                type="primary"
                class="hbtn"
                :disabled="isDisable"
                @click="removeExamineeBatch()"
              >
                批量删除
              </el-button>
            </el-col>
          </el-row>
        </div>

        <!-- 主体table表格 -->
        <el-table
          :data="tableData"
          style="width: 100%"
          stripe
          @selection-change="selectionChange"
          :max-height="tableHeight"
        >
          <el-table-column
            align="center"
            label="全选"
            type="selection"
            min-width="55"
            fixed
          ></el-table-column>
          <el-table-column
            align="center"
            prop="zkzNum"
            label="准考证号"
            min-width="180"
          ></el-table-column>
          <el-table-column
            align="center"
            prop="name"
            label="姓名"
            min-width="180"
          ></el-table-column>
          <el-table-column
            align="center"
            prop="schoolName"
            label="中学"
            min-width="180"
          ></el-table-column>
          <el-table-column
            align="center"
            prop="sfz"
            label="身份证号"
            min-width="180"
          ></el-table-column>
          <el-table-column
            align="center"
            prop="phone"
            label="监护人手机号"
            min-width="180"
          ></el-table-column>
          <el-table-column
            align="center"
            prop=""
            label="操作"
            min-width="180"
            fixed="right"
          >
            <template slot-scope="scope">
              <el-button
                size="mini"
                style="background-color:#DA8309;"
                @click="removeExaminee(scope.row)"
                >移除</el-button
              >
            </template>
          </el-table-column>
        </el-table>

        <!-- 分页器 -->
        <el-pagination
          background
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="queryInfo.pageNum"
          :page-sizes="[10, 20, 30, 40]"
          :page-size="queryInfo.pageSize"
          layout="total, sizes, prev, pager, next, jumper"
          :total="total"
        >
        </el-pagination>
      </div>
    </el-card>

    <!-- dialog 弹框 -->
    <GroupChangeDialog
      :dialogVisibleFu="dialogVisibleFu"
      :dialogText="dialogText"
      :dialogForm="dialogForm"
      @closeDialog="closeDialog"
    />
  </div>
</template>

<script>
import GroupChangeDialog from "c/admin/group/GroupChangeDialog";
import { groupSelectPeople, deleteGroupStudent } from "r/admin/group";
import router from '../../../router';

export default {
  name: "AdjustExaminee",
  prop: {
    subjectName: String,
    examName: String,
  },
  data() {
    return {
      queryInfo: {
        groupId: "",
        pageNum: 1,
        pageSize: 10,
      },
      total: 0, // 数据总条数
      // 弹框文字
      dialogText: {
        dialogTitle: "",
        btnText: "",
      },
      dialogVisibleFu: false, // 控制dialog弹框显示与隐藏
      dialogForm: {}, // 数据对象
      dialogFormRules: {}, // 表单验证规则
      tableData: [], // 表格数据
      selectData: [], //表格中选中项的数据集合
      selectCount: 0, // 表格中选中项的数量
      ids: [], // 选中的列的id数组集合
      isDisable: false, // 提交按钮是否可点击（防重复点击）
      tableHeight: "",
    };
  },
  created() {
    //页面创建时执行一次getHeight进行赋值，顺道绑定resize事件
    window.addEventListener("resize", this.getHeight);
    this.getHeight();
    this.getGroupSelectPeople();
  },
  methods: {
    //定义方法，获取高度减去头尾
    getHeight() {
      this.tableHeight = window.innerHeight - 318 + "px";
    },

    // 获取监控组内人员信息
    async getGroupSelectPeople() {
      this.queryInfo.groupId = this.$route.query.id;
      const { data: res } = await groupSelectPeople(this.queryInfo);
      // console.log(res);
      if (res.code !== 200) return this.$message.error(res.message);
      this.tableData = res.data.studentInfo.records;
      this.total = res.data.studentInfo.total;
    },

    // 控制每页显示几条数据
    handleSizeChange(newSize) {
      this.queryInfo.pageSize = newSize;
      this.getGroupSelectPeople();
    },
    // 控制显示第几页
    handleCurrentChange(newNum) {
      this.queryInfo.pageNum = newNum;
      this.getGroupSelectPeople();
    },
    //返回上一页，并传递参数
    backPage(){ 
       this.$router.push({path:'/admin/group/Lists',query: {pageflag:'false'}})
    },
    // 打开添加指定考生的对话框
    openAddExaminee() {
      (this.dialogForm = {
        examId: this.$route.query.examId,
        name: "",
        subjectId: this.$route.query.subjectId,
        subjectUuid: this.$route.query.subjectUuid,
        pageNum: 1,
        pageSize: 5,
      }),
        (this.dialogVisibleFu = true);
      this.dialogText.dialogTitle = "添加指定考生";
      this.dialogText.btnText = "添 加";
    },

    // 关闭弹框
    closeDialog(value) {
      this.dialogVisibleFu = value;
      // 刷新监控组内考生列表
      this.getGroupSelectPeople();
    },

    // 移除指定考生
    async removeExaminee(data) {
      const confirmResult = await this.$confirm(`确认删除考生：${data.name} ？`, "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
        closeOnClickModal: false,
      }).catch((err) => err);

      if (confirmResult !== "confirm")
        return this.$message.info("您取消了删除");

      // 发送请求
      const { data: res } = await deleteGroupStudent({ 
        ids: data.id, 
        subjectUuid: this.$route.query.subjectUuid 
      });
      if (res.code !== 200) return this.$message.error(res.message);
      this.$message.success(res.data);

      // 刷新监控组考试列表
      this.getGroupSelectPeople();
    },

    // 选中状态改变
    selectionChange(val) {
      // val 指选中的列的数组集合
      // 清空ids数据
      this.selectData = val;
      this.ids = [];
      this.selectCount = val.length;
      val.forEach((item) => {
        // console.log(item.id);
        this.ids.push(item.id);
      });
    },

    // 批量移除指定考生
    async removeExamineeBatch() {
      this.isDisable = true;
      setTimeout(() => {
        this.isDisable = false;
      }, 1000);

      if (this.selectCount <= 0)
        return this.$message.info("您还未选择需要删除的考生");

      const confirmResult = await this.$confirm(
        `确认删除所选 ${this.selectCount} 条数据？`,
        "提示",
        {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        }
      ).catch((err) => err);

      if (confirmResult !== "confirm")
        return this.$message.info("您取消了删除");

      // 发送请求
      const { data: res } = await deleteGroupStudent({
        ids: this.ids.join(","),
        subjectUuid: this.$route.query.subjectUuid
      });
      if (res.code !== 200) return this.$message.error(res.message);
      this.$message.success(res.data);

      // 刷新监控组考试列表
      this.getGroupSelectPeople();
    },
  },
  components: {
    GroupChangeDialog,
  },
};
</script>

<style scoped lang="scss">
@import "a/scss/content";
@import "a/scss/table";
@import "a/scss/page";
</style>
